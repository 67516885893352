define("discourse/plugins/discourse-lti/discourse-lti/api-initializers/hide-lti-login-button", ["exports", "discourse/lib/api", "discourse/models/login-method", "discourse-common/utils/decorators"], function (_exports, _api, _loginMethod, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    var _obj;
    // LTI login must be initiated by the IdP
    // Hide the LTI login button on the client side:
    api.modifyClass("component:login-buttons", (_obj = {
      pluginId: "discourse-lti",
      buttons() {
        return this._super().filter(m => m.name !== "lti");
      }
    }, (_applyDecoratedDescriptor(_obj, "buttons", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "buttons"), _obj)), _obj));

    // Connection is possible, but cannot be initiated
    // by Discourse. It must be initiated by the IdP.
    // Hide the button to avoid confusion:
    const lti = (0, _loginMethod.findAll)().find(p => p.name === "lti");
    if (lti) {
      lti.can_connect = false;
    }
  });
});